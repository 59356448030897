<template>
  <div class="page-timeout">
    <div class="galaxy">
      <div class="card">
        <div class="header">
          <h1>
            <div class="row clearfix">
              <div class="sweet mr-4">
                <div class="sa-icon sa-warning pulseWarning">
                  <span class="sa-body pulseWarningIns"></span>
                  <span class="sa-dot pulseWarningIns"></span>
                </div>
              </div>
              <div>
                <!-- 会话失效 -->
                {{$t('timeout.sessionFailure')}}
              </div>
            </div>
          </h1>
          <p>
            <!-- 很抱歉，您的会话已失效，请重新登录。 -->
            {{$t('timeout.sessionHasExpired')}}
          </p>
        </div>
        <div class="body">
          <div class="mt-4">
            <a href="login" class="btn btn-stealth btn-xl btn-round"><span>
                <!-- 重新登录 -->
                {{$t('timeout.signInAgain')}}
              </span></a>
          </div>
        </div>
      </div>
      <div class="sandland">
        <div class="SkyF2 mr-2">
          <img src="../assets/images/404/SkyF2.svg" class="img-fluid">
        </div>
        <div class="LandF2">
          <img src="../assets/images/404/LandF2.svg" class="img-fluid">
        </div>
      </div>
      <div class="star comet"></div>
    </div>
  </div>
</template>

<script>
$(function(){
  const wH = window.innerHeight;
  const wW = window.innerWidth;
  var x = document.getElementsByClassName("page-timeout");
 
  const generateStars = n => {
    for (let i = 0; i < n; i++) {
      const div = document.createElement('div');
      div.className = i % 20 == 0 ? 'star star--big' : i % 9 == 0 ? 'star star--medium' : 'star';
      // random everywhere!
      div.setAttribute('style', `top:${Math.round(Math.random() * wH)}px;left:${Math.round(Math.random() * wW)}px;animation-duration:${Math.round(Math.random() * 3000) + 3000}ms;animation-delay:${Math.round(Math.random() * 3000)}ms;`);
      x[0].appendChild(div);
    }
  };

  generateStars(150);
})

</script>

<style>
.page-timeout .btn-xl {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 1.875em;
  min-width: 13.75rem !important;
  padding: 0.9375rem;
  border: none;
}

.page-timeout .btn:hover,
.page-timeout .btn:focus {
  -moz-box-shadow: 0 0 18px 0 #8bcec9;
  -webkit-box-shadow: 0 0 18px 0 #8bcec9;
  box-shadow: 0 0 18px 0 #8bcec9;
}

.page-timeout .btn-stealth {
  color: var(--color-white);
  opacity: 0.75;
  background-color: transparent;
  -moz-box-shadow: 0 0 18px 0 #8bcec9;
  -webkit-box-shadow: 0 0 18px 0 #8bcec9;
  box-shadow: 0 0 18px 0 #8bcec9;
}

.page-timeout .btn-stealth:hover,
.page-timeout .btn-stealth:focus {
  color: var(--color-white);
  opacity: 1;
}
</style>
